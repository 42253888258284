/* eslint-disable react/jsx-curly-newline */
/* eslint-disable nonblock-statement-body-position */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// eslint-disable-next-line import/no-named-as-default
import QuestionMark from "../../public/images/question-mark.svg";
import SubmitBox from "./SubmitBox";
import DateBox from "./DateBox";
import Select from "./Select";
import ToolTip from "./ToolTip";

const MonthPicker = ({
  startText,
  endText,
  buttonText,
  submitLink,
  cssClass,
  startDescription,
  endDescription,
}) => {
  const { start, end } = useSelector((state) => state);
  const [neighborhood, setNeighborhood] = React.useState();
  const [viewToolTip, setViewToolTip] = React.useState(false);
  const [residence, setResidence] = React.useState();
  const [url, setUrl] = React.useState();
  const [disable, setDisable] = React.useState();
  const [disableSubmit, setDisableSubmit] = React.useState(true);

  const optionsNeighborhood = [
    { label: "Navigli", value: "navigli" },
    { label: "Sempione", value: "sempione" },
    { label: "City Life", value: "citylife" },
    { label: "Washington", value: "washington" },
  ];

  const optionsResidence = [
    {
      label: "Argelati Fumagalli",
      url: "fumagalli-argelati",
      value: "ArgelatiFumagalli",
      place: "navigli",
    },
    {
      label: "Sempione Ferrucci",
      url: "sempione-ferrucci",
      value: "SempioneFerrucci",
      place: "sempione",
    },
    {
      label: "Lauria 12",
      url: "lauria12",
      value: "Lauria12",
      place: "citylife",
    },
    {
      label: "Elba 14",
      url: "elba14",
      value: "Elba14",
      place: "washington",
    },
  ];

  React.useEffect(() => {
    if (residence) {
      setResidence();
    }
  }, [neighborhood]);

  React.useEffect(() => {
    setDisable(
      optionsNeighborhood.some((option) => option.name === neighborhood)
    );
  }, [neighborhood]);

  React.useEffect(() => {
    setDisableSubmit(
      optionsResidence.some((option) => option.name === residence)
    );
  }, [residence]);

  const handleNeighborhoodChange = (neighborhoodValue) => {
    setNeighborhood(neighborhoodValue);
  };

  const handleResidenceChange = (residenceValue, newUrl) => {
    setResidence(residenceValue);
    setUrl(newUrl);
  };

  const toggleToolTip = () => {
    setViewToolTip(!viewToolTip);
  };

  const closeTooltip = () => {
    setViewToolTip(false);
  };

  const replaceSpaceAndToLowerCase = (name) => {
    return name
      .replace(/\s+/g, "-")
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .toLowerCase();
  };

  return (
    <>
      <div className={`monthpicker-container ${cssClass}`}>
        <div className="month-picker">
          <img
            className="top-question-mark"
            tabIndex={0}
            onBlur={closeTooltip}
            onClick={toggleToolTip}
            src={QuestionMark}
            alt="QuestionMark"
          />
          <ToolTip
            show={viewToolTip}
            classCss="tool-tip-view"
            text={
              <>
                <strong>CHECK IN</strong>
                <br />
                {startDescription}
                <br />
                <br />
                <strong>CHECK OUT</strong>
                <br />
                {endDescription}
              </>
            }
          />
          <div className="place-box place-dates">
            <div className="place">
              <div className="neighborhood">
                <Select
                  label="La tua zona*"
                  options={optionsNeighborhood.sort((a, b) =>
                    a.label > b.label ? 1 : -1
                  )}
                  value={neighborhood}
                  classCss="neighborhood-dropdown"
                  onChange={handleNeighborhoodChange}
                  disable={false}
                  placeholder={"La tua zona"}
                />
              </div>
              <div className="residence">
                <Select
                  label="La tua casa*"
                  options={
                    optionsNeighborhood.some(
                      (option) => option.name !== neighborhood
                    )
                      ? optionsResidence
                          .filter((el) => el.place === neighborhood)
                          .sort((a, b) => (a.label > b.label ? 1 : -1))
                      : []
                  }
                  value={residence}
                  classCss={"residence-dropdown"}
                  onChange={handleResidenceChange}
                  disable={disable}
                  placeholder={"La tua casa"}
                />
              </div>
            </div>
          </div>
          <div className="line" />
          <DateBox
            startText={startText}
            endText={endText}
            startDescription={startDescription}
            endDescription={endDescription}
          />
          <SubmitBox
            submitLink={`${submitLink.trim()}?start=${start.timestamp}&end=${
              end.timestamp
            }&complex=${replaceSpaceAndToLowerCase(residence ? url : "")}`}
            buttonText={buttonText}
            disable={disableSubmit}
          />
        </div>
      </div>
    </>
  );
};

MonthPicker.defaultProps = {
  cssClass: "",
};

MonthPicker.propTypes = {
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  submitLink: PropTypes.string.isRequired,
  helpButtonText: PropTypes.string.isRequired,
  helpHtmlTitle: PropTypes.string.isRequired,
  modalElementId: PropTypes.string.isRequired,
  modalLinkElementId: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
};

export default MonthPicker;

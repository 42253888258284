import React from "react";
import Arrow from "../../public/images/down-arrow.svg";

const Select = ({
  label,
  value,
  options,
  onChange,
  icon,
  classCss,
  disable,
  placeholder,
}) => {
  const selectRef = React.useRef();
  const [openSelect, setOpenSelect] = React.useState(false);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      event.preventDefault();
      event.stopPropagation();
      setOpenSelect(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("click", handleClickOutside, true);
    return () => {
      window.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  React.useLayoutEffect(() => {
    if (openSelect) {
      const { bottom } = selectRef.current.getBoundingClientRect();
      if (window.innerHeight - 100 < bottom) {
        selectRef.current.style.bottom = "45px";
      } else {
        selectRef.current.style.top = "77px";
      }
    }
  }, [openSelect]);

  const handleToggleDropdown = () => {
    if (!disable) {
      setOpenSelect(!openSelect);
    }
  };

  return (
    <>
      <div className="top-note-select">
        {label}
        {icon && <img className="question-mark" src={icon} alt={label} />}
      </div>
      <span
        className={`dropdown ${disable ? "disable" : "enable"}`}
        onClick={handleToggleDropdown}
      >
        <div className="value">{value ? value : placeholder}</div>
        <div className="dropdown-arrow-button">
          <img
            className={`dropdown-arrow ${disable ? "arrow-disable" : ""}`}
            src={Arrow}
            alt="arrow"
          />
        </div>
      </span>
      {openSelect && options.length > 0 && (
        <div ref={selectRef} className={`dropdown-behaviour ${classCss}`}>
          <ul className="picker-items">
            {options.map((option) => {
              return (
                <li
                  key={option.value}
                  onClick={() => {
                    onChange(option.value, option.url);
                    setOpenSelect(!openSelect);
                  }}
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
export default Select;

import React from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import QuestionMark from "../../public/images/question-mark.svg";
import {
  startDayDecreased,
  startDayIncreased,
  startMonthIncreased,
  startMonthDecreased,
  startYearIncreased,
  startYearDecreased,
  endMonthIncreased,
  endMonthDecreased,
  endYearIncreased,
  endYearDecreased,
} from "../store/date";
import {
  isValidEnd,
  isValidEndWithMidMonth,
  isValidStartDay,
  isValidStart,
} from "../utils/dateFunctions";
import DatePickerBox from "./DatePickerBox";
import ToolTip from "./ToolTip";

const DateBox = ({ startText, endText, startDescription, endDescription }) => {
  const dispatch = useDispatch();
  const { start, end } = useSelector((state) => state);
  const [tooltipsState, setTooltipsState] = React.useState({});
  const [checkInView, setCheckInView] = React.useState(false);
  const [checkOutView, setCheckOutView] = React.useState(false);
  const increaseDay = (days) => dispatch(startDayIncreased(days));
  const decreaseDay = (days) => dispatch(startDayDecreased(days));
  const increaseStartMonth = () => dispatch(startMonthIncreased(1));
  const decreaseStartMonth = () => dispatch(startMonthDecreased(1));

  const increaseDayOrMonth = () => {
    if (start.day === 1) {
      increaseDay(15);
    } else {
      increaseDay(1);
      increaseStartMonth();
    }
  };

  const decreaseDayOrMonth = () => {
    if (start.day === 1) {
      decreaseStartMonth();
      decreaseDay(15);
    } else {
      decreaseDay(1);
    }
  };

  const dateBoxes = [
    {
      name: "start-date",
      topNote: startText,
      classCss: "check-in-view",
      description:
        startDescription ||
        "Scegli oggi quando entrare a casa tua, il primo o il quindici del mese",
      openDescription: checkInView,
      toggleDescription: (open) => setCheckInView(open),
      month: {
        day: start.day,
        value: start.month.value,
        timestamp: start.timestamp,
        onUP: () => increaseDayOrMonth(),
        onDown: () => decreaseDayOrMonth(),
        // isValidFunc: () => isValidStartMidMonth(start.previousMonth.timestamp, start.day),
        isValidFunc: () => isValidStartDay(start.date),
      },
      year: {
        value: start.year.value,
        timestamp: start.timestamp,
        onUP: () => dispatch(startYearIncreased(1)),
        onDown: () => dispatch(startYearDecreased(1)),
        isValidFunc: () =>
          isValidStart(DateTime.fromMillis(start.previousYear.timestamp)),
      },
    },
    {
      name: "end-date",
      topNote: endText,
      classCss: "check-out-view",
      description:
        endDescription ||
        "Quanto vuoi restare? Indicaci ora la tua data di Check Out, puoi sempre cambiarla",
      openDescription: checkOutView,
      toggleDescription: (open) => setCheckOutView(open),
      month: {
        day: end.day,
        value: end.month.value,
        timestamp: end.timestamp,
        onUP: () => dispatch(endMonthIncreased(1)),
        onDown: () => dispatch(endMonthDecreased(1)),
        isValidFunc: () =>
          isValidEndWithMidMonth(
            start.date,
            end.previousMonth.timestamp,
            end.day
          ),
      },
      year: {
        value: end.year.value,
        timestamp: end.timestamp,
        onUP: () => dispatch(endYearIncreased(1)),
        onDown: () => dispatch(endYearDecreased(1)),
        isValidFunc: () =>
          isValidEnd(
            start.date,
            DateTime.fromMillis(end.previousYear.timestamp)
          ),
      },
    },
  ];

  return (
    <div className="item-box dates">
      {dateBoxes.map((item) => (
        <div className={item.name} key={item.name}>
          <div className="top-note-datebox">
            {item.topNote}
            <img
              className="question-mark"
              tabIndex={0}
              onBlur={() => setTooltipsState({})}
              onClick={() => {
                setTooltipsState((currentState) => ({
                  [item.name]: !currentState[item.name],
                }));
              }}
              src={QuestionMark}
              alt={item.topNote}
            />
            <ToolTip
              show={tooltipsState[item.name] || false}
              classCss={item.classCss}
              text={item.description}
            />
          </div>
          <div className="date-picker">
            <DatePickerBox
              type="month"
              day={item.month.day}
              value={item.month.value}
              timestamp={item.month.timestamp}
              onUp={item.month.onUP}
              onDown={item.month.onDown}
              isValidFunc={item.month.isValidFunc}
            />
            <DatePickerBox
              type="year"
              value={item.year.value}
              timestamp={item.year.timestamp}
              onUp={item.year.onUP}
              onDown={item.year.onDown}
              isValidFunc={item.year.isValidFunc}
            />
            <div className="bottom-note" />
          </div>
        </div>
      ))}
    </div>
  );
};
DateBox.propTypes = {
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
  startDescription: PropTypes.string,
  endDescription: PropTypes.string,
};

export default DateBox;

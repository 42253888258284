import React from "react";
import PropTypes from "prop-types";

const SubmitBox = ({ submitLink, buttonText, disable }) => {
  return (
    <div className={`item-box ${disable ? "box-disable" : ""}`}>
      <a
        className={`submit-button ${
          disable ? "submit-button-disable" : "submit-button-enable"
        }`}
        href={submitLink}
        target="_blank"
        rel="noreferrer"
      >
        <div className={`text ${disable ? "disable-text" : "enable-text"}`}>
          {buttonText}
        </div>
        <div
          className={`right-arrow ${
            disable ? "right-arrow-disable" : "right-arrow-enable"
          }`}
        />
      </a>
      <span className="mandatory">*campi obbligatori</span>
    </div>
  );
};
SubmitBox.propTypes = {
  buttonText: PropTypes.string.isRequired,
  submitLink: PropTypes.string.isRequired,
};
export default SubmitBox;

import React from "react";

const ToolTip = ({ show, text, classCss }) => {
  if (show) {
    return (
      <div className={classCss}>
        <div className="text-info">{text}</div>
      </div>
    );
  }
  return null;
};
export default ToolTip;
